<script>
export default {
  name: "InstallPage",
  mounted() {
    console.log(
        'LOADING SCRIPTS....'
    );
    this.initBitrix24();
  },
  methods: {
    initBitrix24() {
      // const head0 = document.getElementsByTagName('head')[0];
      // const scriptJquery = document.createElement('script');
      // scriptJquery.setAttribute('src', '//ajax.googleapis.com/ajax/libs/jquery/1.9.1/jquery.min.js');
      // scriptJquery.async = true;
      // scriptJquery.defer = true;
      // head0.appendChild(scriptJquery);
      //
      // const head1 = document.getElementsByTagName('head')[0];
      // const scriptJqueryui = document.createElement('script');
      // scriptJqueryui.setAttribute('src', '//ajax.googleapis.com/ajax/libs/jqueryui/1.10.3/jquery-ui.min.js');
      // scriptJqueryui.async = true;
      // scriptJqueryui.defer = true;
      // head1.appendChild(scriptJqueryui);

      // const head2 = document.getElementsByTagName('head')[0];
      // const scriptBitrix = document.createElement('script');
      // scriptBitrix.setAttribute('src', '//api.bitrix24.com/api/v1/');
      // scriptBitrix.async = true;
      // scriptBitrix.defer = true;
      // head2.appendChild(scriptBitrix);


      const head3 = document.getElementsByTagName('head')[0];
      const scriptJs = document.createElement('script');
      scriptJs.setAttribute('src', '/install/f.js');
      scriptJs.async = true;
      scriptJs.defer = true;
      head3.appendChild(scriptJs);


      const head4 = document.getElementsByTagName('head')[0];
      const scriptInitBx24 = document.createElement('script');
      scriptInitBx24.setAttribute('src', '/install/initbitrix24.js');
      scriptInitBx24.async = true;
      scriptInitBx24.defer = true;
      head4.appendChild(scriptInitBx24);
    }
  }
}
</script>
<template>
  <div></div>
</template>
